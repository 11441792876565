<template>
  <MainPageBlockWrapperNew :title="title" href="/collections">
    <div
      class="flex grid-cols-4 grid-rows-2 flex-wrap justify-between gap-[20px] xl:grid"
    >
      <MainPageCardsReworkedBaseCard
        v-if="isMock"
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 xl:w-full"
        :class="[
          isDesktop
            ? 'lg:col-start-1 lg:row-span-2 xl:col-start-2'
            : 'lg:col-start-3 lg:row-start-1 xl:col-span-1',
        ]"
        disable-label
        disable-announce
        disable-title
        :animation="false"
        :priority-image="data[0].image"
        :data="data[0]"
      />
      <!-- <MainPageCardsReworkedCollectionCard
        v-if="isDesktop"
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-1 lg:row-span-2 xl:col-start-2 xl:w-full"
        disable-label
        :data="data[0]"
      /> -->
      <!-- <MainPageCardsReworkedBaseCard
        v-else
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-3 lg:row-start-1 xl:col-span-1 xl:w-full"
        disable-label
        :data="data[0]"
        disable-announce
      /> -->
      <MainPageCardsReworkedBaseCard
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-3 lg:row-start-1 xl:col-span-1 xl:w-full"
        disable-label
        :data="data[1]"
        disable-announce
      />
      <MainPageCardsReworkedBaseCard
        class="col-span-full w-full md:w-[48.555%] lg:col-span-2 lg:col-start-3 lg:row-start-2 xl:col-span-1 xl:w-full"
        disable-label
        :data="data[2]"
        disable-announce
      />
      <MainPageCardsReworkedBaseCard
        class="col-span-full w-full md:w-[48.555%] lg:col-span-1 lg:col-start-4 lg:row-start-1 xl:w-full"
        disable-label
        :data="data[3]"
        disable-announce
      />
      <MainPageCardsReworkedBaseCard
        class="col-span-full hidden w-full md:w-[48.555%] lg:col-span-1 lg:col-start-4 lg:row-start-2 xl:block xl:w-full"
        disable-label
        :data="data[4]"
        disable-announce
      />
    </div>
  </MainPageBlockWrapperNew>
</template>

<script setup>
defineProps({
  data: {
    type: Array,
    default: () => [],
    require: true,
  },
  title: {
    type: String,
    default: '',
  },
  isMock: {
    type: Boolean,
    default: false,
  },
});

const { isDesktop } = useBreakpoint();
</script>
